import React from "react";
import "./Navbar.css";
import logo from "../../Assets/logopng.png";

const Navbar = () => {
  return (
    <div className="navbar">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
    </div>
  );
};

export default Navbar;
