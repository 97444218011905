import React from "react";
import "./Home.css";
import nws from "../../Assets/newwaysecurity.png";
import nwstaff from "../../Assets/NewWaystaff.png";

const Home = () => {
  return (
    <div className="home">
      <div className="homesection">
        <div className="newwaysection">
          <div className="securityback">
            <div className="content">
              <div className="newwaylogo">
                <img src={nws} alt="logo" />
              </div>
              <div className="discription">
                <span1>Canada's Fastest Growing Security Company</span1>
                <br />
                <br />
                <span>New Way Security</span>
                <p>
                  A leading Canadian security consultancy, is your trusted
                  partner in safeguarding assets. We pride ourselves on our
                  commitment to providing comprehensive, tailored solutions,
                  ensuring unparalleled safety and peace of mind.
                </p>
              </div>
              <div className="linktag">
                <a href="https://newwaysecurity.com/">View Website</a>
              </div>
              <br />
              <div className="sociallinks">
                <div className="links">
                  <div className="heading">
                    <span3>Connect</span3>
                  </div>
                  <div className="ul">
                    <a href="tel: +14372863002">Tel: +1 (437) 2863 002</a>
                    <a href="tel: +14379850552">Tel: +1 (437) 9850 552</a>
                    <a href="https://maps.app.goo.gl/tN3X29CdFJ5dMyKm9">Location</a>
                  </div>
                </div>
                <div className="links">
                  <div className="heading">
                    <span3>Social</span3>
                  </div>
                  <div className="ul">
                    <a href="">Facebook</a>
                    <a href="">Instagram</a>
                    <a href="">LinkedIn</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="newwaysection1">
          <div className="staffback">
            <div className="content1">
              <div className="newwaylogo">
                <img src={nwstaff} alt="logo" />
              </div>
              <div className="discription">
                <span1>Canada's Fastest Growing Recruitment Company</span1>
                <br />
                <br />
                <span>New Way Staff</span>
                <p>
                  As a leader in recruitment and workplace services, we believe
                  that excellent employee performance fuels business success. We
                  are committed to connecting employers and job seekers in a way
                  that fosters inspiration and drives achievement.
                </p>
              </div>
              <div className="linktag1">
                <a href="https://newwaystaff.com/">View Website</a>
              </div>
              <br />
              <div className="sociallinks">
                <div className="links">
                  <div className="heading">
                    <span3>Connect</span3>
                  </div>
                  <div className="ul">
                    <a href="tel: +14372863002">Tel: +1 (437) 2863 002</a>
                    <a href="tel: +14379850552">Tel: +1 (437) 9850 552</a>
                    <a href="https://maps.app.goo.gl/tN3X29CdFJ5dMyKm9">Location</a>
                  </div>
                </div>
                <div className="links">
                  <div className="heading">
                    <span3>Social</span3>
                  </div>
                  <div className="ul">
                    <a href="">Facebook</a>
                    <a href="">Instagram</a>
                    <a href="">LinkedIn</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
