import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
      {/* <div className="f-line"></div> */}
      <div className="f-content">
        <span>© 2024 Newwayglobals, Inc. All rights reserved.</span>
      </div>
    </div>
  )
}

export default Footer